<template>
    <div>
        <div class="chart-container">
          <b-table v-if="widget.widgetConfiguration.type === 'TABLE'"
            ref="refTable"
            :items="this.widgetData.result.pageItems"
            :fields="columns"
            primary-key="id"
            show-empty
            :empty-text="$t('No matching records found')"
            :class="{'table-responsive' : !isShared}"
            @sort-changed="handleSortChange"
          >
            <template #table-busy>
              <div class="text-center my-2">
                <b-spinner class="align-middle" />
              </div>
            </template>

            <template #head()="{ label }">
              <span style="white-space: nowrap">{{ $t(label) }}</span>
            </template>

            <template #cell(title)="{ item }">
              <span class="text-nowrap">
                {{ item.name }}
              </span>
            </template>
          </b-table>
            <kpi-chart v-if="widget.widgetConfiguration.type === 'KPI' && widgetData != null"
              :chartData=widgetData
            />
            <div
              v-if="widget.widgetConfiguration.type !== 'KPI' && widget.widgetConfiguration.type !== 'TABLE'"
              class="graph-container"
            >
            <prozess-chart :type="widget.widgetConfiguration.type" :chart-data="widgetData" />
          </div>
        </div>
    </div>
  </template>
  <script>
import { dashboardService } from '@/services/dashboard'
import KpiChart from '@/components/shared/KpiChart.vue'
import ProzessChart from '@/components/chart/ProzessChart.vue'
import WidgetDataSourceTable from '@/components/Widgets/WidgetDataSourceTable.vue'

export default {

    components: {
        KpiChart,
        ProzessChart,
        WidgetDataSourceTable,
  },
  props: {
        widgetUuid: {
        type: String,
        required: true,
        },
    },
  data() {
    return {
        datasource: {},
        widget: {},
        widgetData: null,
    }
  },

 async mounted() {
    const { data } = await dashboardService('widget').getOne(this.widgetUuid)
    this.widget = data
    const datasourceRes = await dashboardService('datasource').getOne(this.widget.widgetConfiguration.datasourceUuidKey)
    this.datasource = datasourceRes.data

    const widgetData = await dashboardService('widget').getWidgetData(this.widgetUuid, {})
    this.widgetData = widgetData.data
  },
  methods: {
  },
}
  </script>

  <style lang="scss">
  </style>
